import useEntitlementsCheck from './useEntitlementsCheck';

/**
 * Entitlements Wrapper to convert them to simpler boolean checks to use throughout the application
 *
 * In general this should be used over useEntitlementsCheck, as this introduces a layer of abstraction
 * between the specific entitlements and the components using them.
 */

function useEntitlements() {
  const [
    //
    canListAnnotationJobs,
    canListPatients,
    canListClinics,
    canListStaff,
    canListDevices,
    canViewOwnClinic,
    canViewPatientClient,
    //
    canReleaseEcgsAny,
    canReleaseEcgsOwn,
    //
    canApproveBundleAny,
    canApproveBundleOwn,
    //
    canRejectBundleAny,
    canRejectBundleOwn,
    //
    canViewAnnotationJobStats,
    //
    canUploadEcgFileAny,
    canUploadEcgFileOwn,
    //
    canEndProtocolAny,
    canEndProtocolOwn,
    //
    canExtendProtocolAny,
    canExtendProtocolOwn,
    //
    canInitiateProtocolAny,
    canInitiateProtocolOwn,
    //
    canReopenProtocolAny,
    canReopenProtocolOwn,
    //
    canReportMedicationTakenAny,
    canReportMedicationTakenOwn,
    //
    canUnapproveMedicationDosageAny,
    canUnapproveMedicationDosageOwn,
    //
    canChangeMedicationDosageExpectedAtTimeAny,
    canChangeMedicationDosageExpectedAtTimeOwn,
    //
    canApproveEcgBundleAny,
    canApproveEcgBundleOwn,
    //
    canApproveDoseAny,
    canApproveDoseOwn,
    //
    canSeeActionLogAdmin,
    canSeeActionLogClinic,
    //
    canListNotificationsAny,
    canListNotificationsOwn,
    //
    canCreateClinics,
    canUpdateClinics,
    canDeleteClinics,
    canViewClinicConfiguration,
    //
    canCreateDevices,
    canUpdateDevices,
    canDeleteDevices,
    //
    canCreatePatientsAny,
    canCreatePatientsOwn,
    canUpdatePatientsAny,
    canUpdatePatientsOwn,
    canDeletePatientsAny,
    canDeletePatientsOwn,
    //
    canCreateStaffAny,
    canCreateStaffOwn,
    canUpdateStaffAny,
    canUpdateStaffOwn,
    canDeleteStaffAny,
    canDeleteStaffOwn,
    canViewStaffForAnyClinic,
    canViewStaffForOwnClinic,
    //
    canUpdateNotes,
    //
    canDownloadReportAny,
    canDownloadReportOwn,
    //
    canSubmitEcgAnalysisAny,
    canSubmitEcgAnalysisOwn,
    //
    canListPatientconversationsAny,
    canListPatientconversationsOwn,
  ] = useEntitlementsCheck(
    //
    'annotation_portal.list_annotationjobpageresource_entitlement',
    'clinic.list_patientpageresource_entitlement',
    'clinic.list_clinicpageresource_entitlement',
    'clinic.list_clinicstaffpageresource_entitlement',
    'device.list_devicepageresource_entitlement',
    'clinic.view_own_clinicpageresource_entitlement',
    'clinic.view_patient_client_patientpageresource_entitlement',
    //
    'drug_loading_protocol.release_bundle_for_any_clinic_ecgrecordingbundlepageresource_entitlement',
    'drug_loading_protocol.release_bundle_for_own_clinic_ecgrecordingbundlepageresource_entitlement',
    //
    'drug_loading_protocol.approve_bundle_for_any_clinic_ecgrecordingbundlepageresource_entitlement',
    'drug_loading_protocol.approve_bundle_for_own_clinic_ecgrecordingbundlepageresource_entitlement',
    //
    'drug_loading_protocol.reject_bundle_for_any_clinic_ecgrecordingbundlepageresource_entitlement',
    'drug_loading_protocol.reject_bundle_for_own_clinic_ecgrecordingbundlepageresource_entitlement',
    //
    'annotation_portal.statistics_annotationjobpageresource_entitlement',
    //
    'ecg_recording.submit_ecg_file_for_any_clinic_ecgrecordingpageresource_entitlement',
    'ecg_recording.submit_ecg_file_for_own_clinic_ecgrecordingpageresource_entitlement',
    //
    'drug_loading_protocol.end_protocol_for_any_clinic_drugloadingprotocolpageresource_entitlement',
    'drug_loading_protocol.end_protocol_for_own_clinic_drugloadingprotocolpageresource_entitlement',
    //
    'drug_loading_protocol.extend_protocol_for_any_clinic_drugloadingprotocolpageresource_entitlement',
    'drug_loading_protocol.extend_protocol_for_own_clinic_drugloadingprotocolpageresource_entitlement',
    //
    'drug_loading_protocol.initiate_protocol_for_any_clinic_drugloadingprotocolpageresource_entitlement',
    'drug_loading_protocol.initiate_protocol_for_own_clinic_drugloadingprotocolpageresource_entitlement',
    //
    'drug_loading_protocol.reopen_protocol_for_any_clinic_drugloadingprotocolpageresource_entitlement',
    'drug_loading_protocol.reopen_protocol_for_own_clinic_drugloadingprotocolpageresource_entitlement',
    //
    'drug_loading_protocol.report_medication_taken_for_any_clinic_medicationdosagepageresource_entitlement',
    'drug_loading_protocol.report_medication_taken_for_own_clinic_medicationdosagepageresource_entitlement',
    //
    'drug_loading_protocol.unapprove_medication_dosage_for_any_clinic_medicationdosagepageresource_entitlement',
    'drug_loading_protocol.unapprove_medication_dosage_for_own_clinic_medicationdosagepageresource_entitlement',
    //
    'drug_loading_protocol.change_medication_expected_taken_at_for_any_clinic_medicationdosagepageresource_entitlement',
    'drug_loading_protocol.change_medication_expected_taken_at_for_own_clinic_medicationdosagepageresource_entitlement',
    //
    'drug_loading_protocol.approve_bundle_for_any_clinic_ecgrecordingbundlepageresource_entitlement',
    'drug_loading_protocol.approve_bundle_for_own_clinic_ecgrecordingbundlepageresource_entitlement',
    //
    'drug_loading_protocol.approve_medication_dosage_for_any_clinic_medicationdosagepageresource_entitlement',
    'drug_loading_protocol.approve_medication_dosage_for_own_clinic_medicationdosagepageresource_entitlement',
    //
    'action_log.admin_visibility_actionlogresource_entitlement',
    'action_log.clinic_visibility_actionlogresource_entitlement',
    //
    'notification.list_any_clinic_notificationsendrequestlogresource_entitlement',
    'notification.list_own_clinic_notificationsendrequestlogresource_entitlement',
    //
    'clinic.submit_new_clinicpageresource_entitlement',
    'clinic.submit_update_clinicpageresource_entitlement',
    'clinic.submit_delete_clinicpageresource_entitlement',
    'clinic.view_configuration_clinicpageresource_entitlement',
    //
    'device.submit_new_for_any_clinic_devicepageresource_entitlement',
    'device.submit_update_for_any_clinic_devicepageresource_entitlement',
    'device.submit_delete_for_any_clinic_devicepageresource_entitlement',
    //
    'clinic.submit_new_for_any_clinic_patientpageresource_entitlement',
    'clinic.submit_new_for_own_clinic_patientpageresource_entitlement',
    'clinic.update_for_any_clinic_patientpageresource_entitlement',
    'clinic.update_for_own_clinic_patientpageresource_entitlement',
    'clinic.delete_for_any_clinic_patientpageresource_entitlement',
    'clinic.delete_for_own_clinic_patientpageresource_entitlement',
    //
    'clinic.submit_new_for_any_clinic_clinicstaffpageresource_entitlement',
    'clinic.submit_new_for_own_clinic_clinicstaffpageresource_entitlement',
    'clinic.update_for_any_clinic_clinicstaffpageresource_entitlement',
    'clinic.update_for_own_clinic_clinicstaffpageresource_entitlement',
    'clinic.delete_for_any_clinic_clinicstaffpageresource_entitlement',
    'clinic.delete_for_own_clinic_clinicstaffpageresource_entitlement',
    'clinic.view_any_clinicstaffpageresource_entitlement',
    'clinic.view_own_clinicstaffpageresource_entitlement',
    //
    'ecg_recording.update_ecg_notes_ecgrecordingpageresource_entitlement',
    //
    'drug_loading_protocol.download_protocol_report_for_any_clinic_drugloadingprotocolpageresource_entitlement',
    'drug_loading_protocol.download_protocol_report_for_own_clinic_drugloadingprotocolpageresource_entitlement',
    //
    'ecg_analysis.submit_ecg_analysis_for_any_clinic_ecganalysispageresource_entitlement',
    'ecg_analysis.submit_ecg_analysis_for_own_clinic_ecganalysispageresource_entitlement',
    //
    'patient_response.list_any_clinic_patientconversationresource_entitlement',
    'patient_response.list_own_clinic_patientconversationresource_entitlement',
  );

  return {
    canListAnnotationJobs,
    canListPatients,
    canListClinics,
    canListStaff,
    canListDevices,
    canViewOwnClinic,
    canViewPatientClient,
    canReleaseEcgs: canReleaseEcgsAny || canReleaseEcgsOwn,
    canApproveBundle: canApproveBundleAny || canApproveBundleOwn,
    canViewAnnotationJobStats,
    canUploadEcgFile: canUploadEcgFileAny || canUploadEcgFileOwn,
    canEndProtocol: canEndProtocolAny || canEndProtocolOwn,
    canExtendProtocol: canExtendProtocolAny || canExtendProtocolOwn,
    canInitiateProtocol: canInitiateProtocolAny || canInitiateProtocolOwn,
    canReopenProtocol: canReopenProtocolAny || canReopenProtocolOwn,
    canReportMedicationTaken: canReportMedicationTakenAny || canReportMedicationTakenOwn,
    canUnapproveMedicationDosage:
      canUnapproveMedicationDosageAny || canUnapproveMedicationDosageOwn,
    canChangeMedicationDosageExpectedAtTime:
      canChangeMedicationDosageExpectedAtTimeAny || canChangeMedicationDosageExpectedAtTimeOwn,
    canApproveEcgBundle: canApproveEcgBundleAny || canApproveEcgBundleOwn,
    canApproveDose: canApproveDoseAny || canApproveDoseOwn,
    canSeeActionLog: canSeeActionLogAdmin || canSeeActionLogClinic,
    canSeeActionLogAdmin,
    canSeeActionLogClinic,
    canListNotifications: canListNotificationsAny || canListNotificationsOwn,
    canCreateClinics,
    canEditClinic: canUpdateClinics,
    canDeleteClinic: canDeleteClinics,
    canViewClinicConfiguration,
    canCreateDevices,
    canUpdateDevices,
    canDeleteDevices,
    canCreatePatients: canCreatePatientsAny || canCreatePatientsOwn,
    canUpdatePatients: canUpdatePatientsAny || canUpdatePatientsOwn,
    canDeletePatients: canDeletePatientsAny || canDeletePatientsOwn,
    canCreateStaff: canCreateStaffAny || canCreateStaffOwn,
    canUpdateStaff: canUpdateStaffAny || canUpdateStaffOwn,
    canDeleteStaff: canDeleteStaffAny || canDeleteStaffOwn,
    canUpdateNotes,
    canDownloadReport: canDownloadReportAny || canDownloadReportOwn,
    canViewStaffForClinic: canViewStaffForAnyClinic || canViewStaffForOwnClinic,
    canRejectEcg: canRejectBundleAny || canRejectBundleOwn,
    canSubmitEcgAnalysis: canSubmitEcgAnalysisAny || canSubmitEcgAnalysisOwn,
    canListPatientConversations: canListPatientconversationsAny || canListPatientconversationsOwn,
  };
}

export default useEntitlements;
